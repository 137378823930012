import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogTrigger, DialogSurface, Input, Label, Textarea, makeStyles, shorthands, TagGroupProps, Tag, TagGroup, Dropdown, Option } from "@fluentui/react-components";
import { IChatPrompt } from "../../../libs/models/ChatPrompt";
import { FC, useEffect, useState } from "react";
import { usePrompts } from "../../../libs/hooks/usePrompts";
import { ICreateEditChatPrompt } from "../../../libs/models/CreateEditChatPrompt";
import { usePersonas } from "../../../libs/hooks/usePersonas";
import { IChatPersona } from "../../../libs/models/ChatPersona";


const useStyles = makeStyles({
    outer: {
        ...shorthands.margin('24px'),
    },
    root: {},
    actions: {
        paddingTop: '10%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginTop: '5px',
        marginBottom: '20px',
    },
    tagGroup: {
        marginTop: '5px',
    },
    dangerButton: {
        backgroundColor: '#7a0012',
        color: 'white',
    },
});

interface EditPromptDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    prompt: IChatPrompt | undefined;
    onRefresh: () => void;
}

export const EditPromptDialog: FC<EditPromptDialogProps> = ({open, onCloseDialog, prompt, onRefresh}) => {
    const styles = useStyles();
    const promptHook = usePrompts();
    const personasHook = usePersonas();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [promptText, setPromptText] = useState("");
    const [tagInputValue, setTagInputValue] = useState("");
    const [tags, setTags] = useState([] as string[]);
    const [personas, setPersonas] = useState<IChatPersona[]>([]);
    const [selectedPersonaId, setSelectedPersonaId] = useState<string | undefined>(undefined);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        if (open) {
            personasHook.getPersonasByUser().then((result) => {
                setPersonas(result);
            }).catch((error) => {
                console.error("Failed to fetch personas:", error);
            });
        }
    }, [open]);

    useEffect(() => {
        if (prompt != undefined) {
            setName(prompt.name);
            setDescription(prompt.description);
            setPromptText(prompt.prompt);
            setTags(prompt.tags);
            setSelectedPersonaId(prompt.personaId);
        }
    }, [prompt]);

    const handleSubmit = () => {
        if (prompt == undefined) {
            console.error("Prompt is undefined");
            return;
        }
        const updatedPrompt: ICreateEditChatPrompt = {
            id: prompt.id,
            name,
            description,
            prompt: promptText,
            user: prompt.user,
            tags,
            plugins: prompt.plugins,
            promptType: prompt.promptType,
            personaId: selectedPersonaId,
            personaName: personas.find(x => x.id == selectedPersonaId)?.name,
        }
        promptHook.updatePrompt(updatedPrompt).then(() => {
            onRefresh();
            onCloseDialog();
        }).catch((error) => {
            console.error("Failed to update prompt:", error);
        });
    }

    const handleDelete = () => {
        if (prompt == undefined) {
            console.error("Prompt is undefined");
            return;
        }
        promptHook.deletePrompt(prompt.id).then(() => {
            setDeleteDialogOpen(false);
            onRefresh();
            onCloseDialog();
        }).catch((error) => {
            console.error("Failed to delete prompt:", error);
        });
    }

    const removeTag: TagGroupProps["onDismiss"] = (_e, {value}) => {
        setTags([...tags].filter(tag => tag !== value));
    }

    const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && tagInputValue !== "" && !tags.includes(tagInputValue)) {
            setTags([...tags, tagInputValue]);
            setTagInputValue("");
            event.preventDefault()
        }
    }

    return (
        <div>
            <Dialog open={open}>
                <DialogSurface className={styles.root}>
                    <DialogBody>
                        <DialogContent className={styles.dialogContent}>
                            <Label>Name</Label>
                            <Input className={styles.input} value={name} onChange={(_e, data) => {
                                setName(data.value || "")
                            }}/>
                            <Label>Description</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '100px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={description}
                                onChange={(_e, data) => {
                                    setDescription(data.value || "")
                                }}/>
                            <Label>Prompt</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '200px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={promptText}
                                onChange={(_e, data) => {
                                    setPromptText(data.value || "")
                                }}/>
                            <Label>Persona</Label>
                            <Dropdown
                                className={styles.input}
                                placeholder={"Select a Persona"}
                                selectedOptions={selectedPersonaId ? [selectedPersonaId] : []}
                                value={personas.find(p => p.id === selectedPersonaId)?.name ?? ""}
                                onOptionSelect={(_, data) => {
                                    setSelectedPersonaId(data.optionValue);
                                }}
                            >
                                {personas.map((persona) => (
                                    <Option key={persona.id} value={persona.id}>
                                        {persona.name}
                                    </Option>
                                ))}
                            </Dropdown>
                            <Label>Tags</Label>
                            <TagGroup
                                className={styles.tagGroup}
                                onDismiss={removeTag}
                            >
                                {tags.map((tag) => (
                                    <Tag
                                        dismissible
                                        dismissIcon={{"aria-label": "remove"}}
                                        value={tag}
                                        key={tag}
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                            </TagGroup>
                            <Input className={styles.input} onKeyDown={addTag} value={tagInputValue} onChange={(e) => {setTagInputValue(e.target.value)}}/>
                        </DialogContent>
                        <DialogActions className={styles.actions}>
                            <DialogTrigger>
                                <Button className={styles.dangerButton} appearance="primary" onClick={() => {
                                    setDeleteDialogOpen(true)
                                }}>Delete</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={onCloseDialog}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="primary" onClick={handleSubmit}>Edit</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={deleteDialogOpen}>
                <DialogSurface className={styles.root}>
                    <DialogBody>
                        <DialogContent>
                            Are you sure you want to delete this prompt?
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={() => {
                                    setDeleteDialogOpen(false)
                                }}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button className={styles.dangerButton} appearance="primary" onClick={handleDelete}>Delete</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    )
}

import {
    Input,
    makeStyles,
    SelectTabEventHandler,
    shorthands,
    Tab,
    TabList,
    TabValue,
    Button,
} from '@fluentui/react-components';
import { SearchRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { usePrompts } from '../../../libs/hooks/usePrompts';
import { IChatPrompt } from '../../../libs/models/ChatPrompt';
import { PromptCard } from '../prompt-library/PromptCard';
import { AddPromptDialog } from '../../admin/Prompts/AddPromptDialog';
import { EditPromptDialog } from '../../admin/Prompts/EditPromptDialog';

const useStyles = makeStyles({
    root: {
        ...shorthands.margin('24px'),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '24px',
    },
    tabList: {
        marginBottom: '16px',
    },
    search: {
        maxWidth: '400px',
        flexGrow: 1,
        marginRight: '16px',
    },
    main: {
        ...shorthands.gap('36px'),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    section: {
        width: 'fit-content',
    },
    headerActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
});

export const PromptsTab: React.FC = () => {
    const styles = useStyles();
    const promptsHook = usePrompts();
    const [publicPrompts, setPublicPrompts] = useState<IChatPrompt[]>([]);
    const [personalPrompts, setPersonalPrompts] = useState<IChatPrompt[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedTab, setSelectedTab] = useState<TabValue>('public');
    const [isNewPromptDialogOpen, setIsNewPromptDialogOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<IChatPrompt | undefined>(undefined);
    const [isEditPromptsDialogOpen, setIsEditPromptsDialogOpen] = useState(false);

    useEffect(() => {
        promptsHook
            .getAllPrompts()
            .then((result) => {
                setPublicPrompts(result);
            })
            .catch((error) => {
                console.error('Failed to fetch public prompts:', error);
            });

        promptsHook
            .getPersonalPrompts()
            .then((result) => {
                setPersonalPrompts(result);
            })
            .catch((error) => {
                console.error('Failed to fetch personal prompts:', error);
            });
    }, [refresh]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleTabSelect: SelectTabEventHandler = (_event, data) => {
        setSelectedTab(data.value);
    };

    const filterPrompts = (prompts: IChatPrompt[]) => {
        const searchTerms = searchTerm
            .toLowerCase()
            .split(',')
            .map((term) => term.trim());
        return prompts.filter(
            (prompt) =>
                prompt.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                searchTerms.every((term) => prompt.tags.some((tag) => tag.toLowerCase().includes(term))),
        );
    };

    const filteredPrompts = selectedTab === 'public' ? filterPrompts(publicPrompts) : filterPrompts(personalPrompts);

    const handleEditPrompt = (prompt: IChatPrompt) => {
        setSelectedPrompt(prompt);
        setIsEditPromptsDialogOpen(true);
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <TabList selectedValue={selectedTab} onTabSelect={handleTabSelect} className={styles.tabList}>
                    <Tab value="public">Public Prompts</Tab>
                    <Tab value="personal">Personal Prompts</Tab>
                </TabList>
                <div className={styles.headerActions}>
                    <Input
                        contentBefore={<SearchRegular/>}
                        className={styles.search}
                        onChange={handleSearchChange}
                        placeholder="Search by name or tags (comma-separated)..."
                    />
                    {selectedTab === 'personal' && (
                        <Button
                            appearance="primary"
                            onClick={() => {
                                setIsNewPromptDialogOpen(true);
                            }}
                        >
                            Add Prompt
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.main}>
                {filteredPrompts.map((prompt, index) => (
                    <PromptCard
                        key={index}
                        id={prompt.id}
                        title={prompt.name}
                        description={prompt.description}
                        persona={prompt.personaName}
                        prompt={prompt.prompt}
                        tags={prompt.tags}
                        isPersonal={selectedTab === 'personal'}
                        onEdit={() => {
                            handleEditPrompt(prompt);
                        }}
                    />
                ))}
            </div>
            <AddPromptDialog
                open={isNewPromptDialogOpen}
                onCloseDialog={() => {
                    setIsNewPromptDialogOpen(false);
                }}
                onRefresh={() => {
                    setRefresh(!refresh);
                }}
                promptType="PRIVATE"
            />
            {selectedPrompt && (
                <EditPromptDialog
                    open={isEditPromptsDialogOpen}
                    onCloseDialog={() => {
                        setIsEditPromptsDialogOpen(false);
                    }}
                    prompt={selectedPrompt}
                    onRefresh={() => {
                        setRefresh(!refresh);
                    }}
                />
            )}
        </div>
    );
};

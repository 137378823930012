import { Text, Caption1, Card, CardHeader, shorthands, makeStyles, tokens, Button } from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { setConversationInput, setPrompt } from "../../../redux/features/conversations/conversationsSlice";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { setSelectedChatWindowTab } from "../../../redux/features/app/appSlice";
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { useState } from "react";
import { PromptDialog } from './PromptDialog';

const useStyles = makeStyles({
    card: {
        width: "360px",
        maxWidth: "100%",
        minHeight: "120px",
        height: "auto",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
    text: {
        ...shorthands.margin(0),
    },
    personaText: {
        color: tokens.colorNeutralForeground3,
        fontSize: '12px',
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        ...shorthands.margin(0),
        fontStyle: 'italic',
    },
    cardContent: {
        position: 'relative',
        paddingBottom: '24px', // Make room for persona text
    },
});

interface PromptCardProps {
    id: string;
    title: string;
    description: string;
    prompt: string;
    persona: string | undefined;
    tags: string[] | undefined;
    isPersonal: boolean;
    onEdit: () => void;
}

export const PromptCard: React.FC<PromptCardProps> = ({id, title, description, prompt, tags, isPersonal, onEdit, persona}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {selectedId} = useAppSelector((state: RootState) => state.conversations);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const templateVariables = prompt.match(/\[.*?\]/g) ?? [];

    const dispatchPrompt = (promptForDispatch: string) => {
        dispatch(setConversationInput({id: selectedId, content: promptForDispatch}));
        dispatch(setPrompt({chatId: selectedId, promptId: id, promptName: title}));
        dispatch(setSelectedChatWindowTab('chat'));
    }

    const onClick = () => {
        if (templateVariables.length > 0) {
            setIsDialogOpen(true);
            return;
        }

        dispatchPrompt(prompt);
    }

    return (
        <>
            <Card className={styles.card} onClick={onClick}>
                <CardHeader
                    header={<Text weight="semibold">{title}</Text>}
                    description={
                        <Caption1 className={styles.caption}>{tags ? tags.join(", ") : ""}</Caption1>
                    }
                    action={
                        isPersonal ? (
                            <Button
                                appearance="transparent"
                                icon={<MoreHorizontal20Regular />}
                                aria-label="Edit prompt"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent card click event
                                    onEdit();
                                }}
                            />
                        ) : null
                    }
                />
                <div className={styles.cardContent}>
                    <p className={styles.text}>
                        {description}
                    </p>
                    {persona && (
                        <p className={styles.personaText}>
                            {persona}
                        </p>
                    )}
                </div>
            </Card>
            <PromptDialog
                open={isDialogOpen}
                onCloseDialog={() => { setIsDialogOpen(false); }}
                dispatchPrompt={dispatchPrompt}
                prompt={prompt}
                templateVariables={templateVariables}
            />
        </>
    )
}

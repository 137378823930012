import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, Input, makeStyles, shorthands } from "@fluentui/react-components"
import { useState } from "react";

const useStyles = makeStyles({
    root: {},
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('16px'),
    },
    promptContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        ...shorthands.gap('4px'),
    },
    inlineInput: {
        minWidth: '120px',
        maxWidth: '200px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface PromptDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    dispatchPrompt: (promptForDispatch: string) => void;
    prompt: string;
    templateVariables: string[];
}

export const PromptDialog: React.FC<PromptDialogProps> = ({open, onCloseDialog, dispatchPrompt, prompt, templateVariables}) => {
    const styles = useStyles();
    const [variableValues, setVariableValues] = useState<Record<string, string>>(
        Object.fromEntries(templateVariables.map(v => [v, '']))
    );

    const handleInputChange = (variable: string, value: string) => {
        setVariableValues(prev => ({
            ...prev,
            [variable]: value
        }));
    };

    const handleSubmit = () => {
        let finalPrompt = prompt;
        Object.entries(variableValues).forEach(([variable, value]) => {
            finalPrompt = finalPrompt.replace(variable, value);
        });
        dispatchPrompt(finalPrompt);
        onCloseDialog();
    };

    const promptSegments = prompt.split(/(\[.*?\])/g);

    return (
        <Dialog open={open}>
            <DialogSurface className={styles.root}>
                <DialogBody>
                    <DialogContent className={styles.dialogContent}>
                        <div className={styles.promptContainer}>
                            {promptSegments.map((segment, index) => {
                                const isVariable = templateVariables.includes(segment);
                                if (isVariable) {
                                    return (
                                        <Input
                                            key={index}
                                            className={styles.inlineInput}
                                            value={variableValues[segment]}
                                            onChange={(e) => { handleInputChange(segment, e.target.value); }}
                                            placeholder={segment.replace(/[\[\]]/g, '')}
                                        />
                                    );
                                }
                                return <span key={index}>{segment}</span>;
                            })}
                        </div>
                    </DialogContent>
                    <DialogActions className={styles.actions}>
                        <DialogTrigger>
                            <Button appearance="secondary" onClick={onCloseDialog}>Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger>
                            <Button appearance="primary" onClick={handleSubmit}>Create</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

import {
    Dialog,
    DialogContent,
    DialogBody,
    Label,
    DialogSurface,
    makeStyles,
    shorthands,
    Input,
    Textarea,
    DialogTrigger,
    DialogActions,
    Button,
    TagGroupProps,
    TagGroup,
    Tag,
    Dropdown,
    Option
} from "@fluentui/react-components";
import { FC, useState, useEffect } from "react";
import { usePrompts } from "../../../libs/hooks/usePrompts";
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { ICreateEditChatPrompt } from "../../../libs/models/CreateEditChatPrompt";
import { usePersonas } from "../../../libs/hooks/usePersonas";
import { IChatPersona } from "../../../libs/models/ChatPersona";


const useStyles = makeStyles({
    outer: {
        ...shorthands.margin('24px'),
    },
    root: {},
    actions: {
        paddingTop: '10%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginTop: '5px',
        marginBottom: '20px',
    },
    tagGroup: {
        marginTop: '5px',
    }
});

interface AddPromptDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    onRefresh: () => void;
    promptType: "GLOBAL" | "PRIVATE";
}

export const AddPromptDialog: FC<AddPromptDialogProps> = ({open, onCloseDialog, onRefresh, promptType}) => {
    const styles = useStyles();
    const promptHook = usePrompts();
    const {activeUserInfo} = useAppSelector((state: RootState) => state.app);
    const personasHook = usePersonas();
    const [personas, setPersonas] = useState<IChatPersona[]>([]);
    const [selectedPersonaId, setSelectedPersonaId] = useState<string | undefined>(undefined);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [promptText, setPromptText] = useState("");
    const [tagInputValue, setTagInputValue] = useState("");
    const [tags, setTags] = useState([] as string[]);

    useEffect(() => {
        if (open) {
            personasHook.getPersonasByUser().then((result) => {
                setPersonas(result);
            }).catch((error) => {
                console.error("Failed to fetch personas:", error);
            });
        }
    }, [open]);

    const handleSubmit = () => {
        const newPrompt: ICreateEditChatPrompt = {
            id: uuidv4(),
            name,
            description,
            prompt: promptText,
            user: activeUserInfo?.id,
            tags,
            plugins: [],
            promptType: promptType,
            personaId: selectedPersonaId,
            personaName: personas.find(x => x.id == selectedPersonaId)?.name,
        };

        promptHook.createPrompt(newPrompt).then(() => {
            onCloseDialog();
            onRefresh();
        }).catch((error) => {
            console.error("Failed to create prompt:", error);
        });
    };

    const removeTag: TagGroupProps["onDismiss"] = (_e, {value}) => {
        setTags([...tags].filter(tag => tag !== value));
    }

    const addTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && tagInputValue !== "" && !tags.includes(tagInputValue)) {
            setTags([...tags, tagInputValue]);
            setTagInputValue("");
            event.preventDefault()
        }
    }

    return (
        <div>
            <Dialog open={open}>
                <DialogSurface className={styles.root}>
                    <DialogBody>
                        <DialogContent className={styles.dialogContent}>
                            <Label>Name</Label>
                            <Input className={styles.input} value={name} onChange={(_e, data) => {
                                setName(data.value || "")
                            }}/>
                            <Label>Description</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '100px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={description}
                                onChange={(_e, data) => {
                                    setDescription(data.value || "")
                                }}/>
                            <Label>Prompt</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '200px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={promptText}
                                onChange={(_e, data) => {
                                    setPromptText(data.value || "")
                                }}/>
                            <Label>Persona</Label>
                            <Dropdown
                                className={styles.input}
                                placeholder={"Select a Persona"}
                                selectedOptions={selectedPersonaId ? [selectedPersonaId] : []}
                                value={personas.find(p => p.id === selectedPersonaId)?.name ?? ""}
                                onOptionSelect={(_, data) => {
                                    setSelectedPersonaId(data.optionValue);
                                }}
                            >
                                {personas.map((persona) => (
                                    <Option key={persona.id} value={persona.id}>
                                        {persona.name}
                                    </Option>
                                ))}
                            </Dropdown>
                            <Label>Tags</Label>
                            <TagGroup
                                className={styles.tagGroup}
                                onDismiss={removeTag}
                            >
                                {tags.map((tag) => (
                                    <Tag
                                        dismissible
                                        dismissIcon={{"aria-label": "remove"}}
                                        value={tag}
                                        key={tag}
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                            </TagGroup>
                            <Input className={styles.input} onKeyDown={addTag} value={tagInputValue} onChange={(e) => {setTagInputValue(e.target.value)}}/>
                        </DialogContent>
                        <DialogActions className={styles.actions}>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={onCloseDialog}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="primary" onClick={handleSubmit}>Create</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
}
